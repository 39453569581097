import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image';
import React from 'react';

const StructuredImage = ({ image, alt }) => {
    const asset = getImage(image);
    return (
        <div className="w-full col-span-full grid grid-cols-18 relative">
            <div className="col-start-2 col-end-17 lg:col-start-4 lg:col-end-15 max-w-screen-lg mx-auto w-11/12 relative flex">
                <GatsbyImage image={asset} className="my-12 clipped-img mx-auto" alt={alt ? alt : ''} />
                <div className="absolute lg:flex flex-col -right-4 top-[40%] gap-1 hidden">
                    <span className="block bg-darks5 rounded w-3 h-0.5" />
                    <span className="block bg-darks5 rounded w-3 h-0.5" />
                </div>
            </div>
            <div className="absolute w-full h-full z-[-10] hidden lg:block">
                <div className="relative w-full h-full">
                    <div className="absolute top-[45%] w-full h-full">
                        <span className="w-full h-[1px] bg-darks5 block opacity-60" />

                        <StaticImage src="../assets/images/drips/drip_texture_03.png" alt="" className="!absolute left-0 h-9 !z-[-10]" objectFit="contain" />

                        <div className="absolute top-3 right-3">
                            <span className="h-1 w-24 bg-darks5 rounded block z-20" />
                            <StaticImage src="../assets/images/drips/drip_texture_04.png" alt="" className="!absolute top-0.5 h-11 !z-[-10]" objectFit="contain" />
                        </div>
                    </div>
                    <div className="absolute bottom-12 right-0">
                        <div className="border-t border-l border-b border-darks5 h-32 p-3 pb-2 flex items-end justify-center">
                            <div className="grid grid-cols-4 grid-rows-2">
                                <span className="block w-1.5 h-1.5" />
                                <span className="block bg-gray w-1.5 h-1.5" />
                                <span className="block w-1.5 h-1.5" />
                                <span className="block bg-gray w-1.5 h-1.5" />
                                <span className="block bg-gray w-1.5 h-1.5" />
                                <span className="block w-1.5 h-1.5" />
                                <span className="block bg-gray w-1.5 h-1.5" />
                                <span className="block w-1.5 h-1.5" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StructuredImage;